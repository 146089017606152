import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Heading1 from '../components/Heading1'
import OtherArticles from '../components/OtherArticles'
import Share from '../components/Share';

import styles from "./article.module.scss"
import urljoin from "url-join";

class ArticlePostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const author = this.props.data.site.siteMetadata.author
    const url = urljoin(this.props.data.site.siteMetadata.siteUrl, this.props.path)
    const blogImagePath = post.frontmatter.kvPath == null ? this.props.data.site.siteMetadata.defaultOgpImage : post.frontmatter.kvPath
    const blogImageUrl = urljoin(this.props.data.site.siteMetadata.siteUrl, blogImagePath)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Helmet>
          <meta property="og:title" content={post.frontmatter.title} />
          <meta property="og:description" content={post.excerpt} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:image" content={blogImageUrl} />
          {/* <meta property="fb:app_id" content={facebookAppId} /> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={author} />
        </Helmet>

        <div className={styles.content_header}>
          {post.frontmatter.kvPath == null ? (
            <p></p>
            ) : (
            <img className={styles.content_header__kv} src={post.frontmatter.kvPath} alt={post.frontmatter.title}></img>
          )}
          <Heading1>{post.frontmatter.title}</Heading1>
          <p className={styles.content_header__last_updated}>最終更新日: {post.frontmatter.date}</p>
          <Share postUrl={url} postNode={post} />
        </div>

        {/* 以下がブログ本文 */}
        <div className={styles.content_body} dangerouslySetInnerHTML={{ __html: post.html }} />

        <Share postUrl={url} postNode={post} />
        <div className={styles.content_others}>
          <OtherArticles currentTitle={post.frontmatter.title}></OtherArticles>
        </div>
      </Layout>
    )
  }
}

export default ArticlePostTemplate

export const pageQuery = graphql`
  query ArticlePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        defaultOgpImage
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        kvPath
      }
    }
  }
`
