import React, { Component } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from "react-share";

import styles from "./index.module.scss"

class Share extends Component {
  render() {
    const { postNode, postUrl } = this.props;
    const post = postNode.frontmatter;
    const iconSize = 28;

    return (
      <div className={styles.social_links}>
        <TwitterShareButton url={postUrl} title={post.title}>
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>
        <FacebookShareButton url={postUrl} quote={postNode.excerpt}>
          <FacebookIcon round size={iconSize} />
        </FacebookShareButton>
        <LineShareButton url={postUrl} quote={postNode.excerpt}>
          <LineIcon round size={iconSize} />
        </LineShareButton>
      </div>
    );
  }
}

export default Share;
