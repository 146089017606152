import React from 'react'
import styles from "./index.module.scss"
import { Link } from "gatsby"

import { StaticQuery, graphql } from 'gatsby'

export default (currentTitle) => (
  <StaticQuery
    query={graphql`
      query {
        articleList:
          allMarkdownRemark (
            filter: {fileAbsolutePath: {regex: "/\/articles\//"}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 300
          )
          {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date
                }
                fields {
                  slug
                }
                excerpt
              }
            }
          }
      }
    `}
    render={data => (
      <div>
        <h2 className={styles.header}>他の記事</h2>
        <ul className={styles.list}>
          {data.articleList.edges.filter(({node}) => node.frontmatter.title !== currentTitle.currentTitle).map(({node}, index) => (
            <li className={styles.list_item} key={index}>
              <Link to={node.fields.slug} className={styles.link}>
                { node.frontmatter.title }
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  />
)
